/* Font family */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*****gilroyregular Font******/

body {
  /* font-family: "gilroy-regular"; */
  font-family: "Poppins";
  font-size: 14px;
  color: #101010;
  overflow-y: scroll;
}

/* OKR */

::placeholder {
  color: #acacac;
  font-size: 12px;
}
.top-border-radius {
  border-radius: 20px 20px 0px 0px !important;
}
.textGrey {
  color: #5f5f5f;
}
.textSecondary {
  color: #707070;
}
.textLightGrey {
  color: #a5a5a5;
}
.textDarkGrey {
  color: #888888;
}
.textBlack {
  color: #0a1b46;
}
.textDarkBlack {
  color: #252525;
}
.textDark {
  color: #101010;
}
.textBlue {
  color: #294ba0;
}
.textDarkBlue {
  color: #5565b6;
}
.textBlueLight {
  color: #4d75db;
}
.textBlueLight1 {
  color: #6993ff;
}
.zIndex {
  z-index: 99999;
}
/* Sign up page css */
.login-form-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 20px;
  padding: 4rem 3rem;
  width: 450px;
}
.signup-icon {
  width: 50px;
  height: 50px;
}
.devider-line-icon {
  width: 240px;
}
.login-icon {
  width: 190px;
}
.login-page {
  min-height: 100vh;
  background-color: #f7f9ff;
  overflow-x: hidden;
}
.bannerHeading {
  font-size: 4rem;
  color: #0a1b46;
  font-weight: 800;
}
.bannerHeading span {
  font-size: 4rem;
  color: #6993ff;
}
.hiddenBannerText {
  letter-spacing: 0px;
  color: #294ba0;
  opacity: 0.09;
  font-weight: bold;
  font-size: 8rem;
}
.formControl {
  padding: 0.6rem 1rem;
  border: 0px solid #ced4da;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.sign-up-button-color {
  background: #6993ff;
  border-radius: 28px;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
}

.sign-up-button-color-onempty {
  background: #acacac;
  border-radius: 28px;
}

.custom-input-text-primary {
  color: #1e2838;
  background: #f6f6f6 !important;
  border-radius: 10px;
  /* border: none; */
}

.custom-input-text-primary:focus {
  color: #1e2838;
  background: #f6f6f6;
  border-radius: 10px;
  border: none;
  box-shadow: none;
}
.otp-input {
  width: 60px;
  height: 60px;
}
.backArrow {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 992px) and (min-width: 1057px) {
  .tab-border {
    width: 73%;
    top: 12.5%;
  }
}
.mandatory-star {
  color: #ff6767;
  margin-right: 3px;
}

.form-control {
  padding: 0.8rem 1rem;
  background-color: #f7f9ff;
  /* border: 0px solid #ced4da; */
}
.set-objective-button {
  background: #ffffff;
  border: 1px solid #6993ff;
  border-radius: 27px;
  color: #6993ff;
}

.set-objective-button:hover {
  background: #6993ff;
  border: 1px solid #6993ff;
  border-radius: 27px;
  color: #fff;
}

.setObjectiveListBox {
  background: #f7f9ff;
  border-radius: 20px;
  align-items: center;
}
.border-right {
  border-right: 1px solid #a3abd8;
}
.modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
}
.modal-dialog {
  background: #ffffff;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 20px;
}

.notificatiDropdown .dropdown-menu {
  min-width: 25rem !important;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 0px;
  overflow-y: scroll;
}
.text1 {
  background: #ffffff;
  padding: 10px;
}
.avtarProfile {
  padding: 10px 18px;
}
.notificationTime {
  color: #d1d1d1;
}
.welcomeHeading {
  background: #eaf0ff;
  color: #0a1b46;
  padding: 20px 10px;
  border-radius: 20px;
  font-weight: 600;
}
.bg-light-blue {
  background: #f7f9ff;
  border-radius: 12px !important;
}

/* progress bar */
.progressP {
  width: 130px;
  height: 130px;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}
.progress1 {
  width: 100px;
  height: 100px;
}
.progressP:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid lightgrey;
  position: absolute;
  top: 0;
  left: 0;
}
.progressP > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progressP .progress-left {
  left: 0;
}
.progressP .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progressP .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progressP .progress-right {
  right: 0;
}
.progressP .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progressP .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #fff;
  font-size: 20px;
  color: #6993ff;
  line-height: 96px;
  text-align: center;
  position: absolute;
  top: 15%;
  left: 7%;
}
.progress1 .progress-value {
  top: 4%;
}
.progressP.blue .progress-bar {
  border-color: #6993ff;
}
.progressP.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progressP.yellow .progress-bar {
  border-color: #fdba04;
}
.progressP.yellow .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}
.progressP.yellow .progress-left .progress-bar {
  animation: none;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.progress-line {
  width: 100%;
  float: left;
  background: none;
  box-shadow: none;
  position: relative;
}

.progress-bar-line {
  width: 100%;
  height: 100%;
  background: none;
  border-style: solid;
  border-color: #006666;
  border-radius: 5px;
}
.btnOrganization,.btnOrganization:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6993ff;
  border-radius: 43px;
  width: 100%;
  color: #6993ff;
}

.progressCard {
  background: #ffffff;
  box-shadow: 0px 5px 20px #0000000d;
  border-radius: 12px;
}

.swiper-slide-img {
  height: 50px !important;
  object-fit: cover !important;

}
.departmentHeading {
  background: #e1e9ff;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
  border-radius: 40;
}
.row.departmentCards {
  background: #f7f9ff;
  border-radius: 10px;
}
.swiper-button-prev,
.swiper-button-next {
  opacity: 1 !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
}
.form-select {
  font-size: 14px;
  background-color: #eaf0ff;
  border: 0px solid #ced4da;
  padding: 0rem 2.25rem 0rem 0.75rem;
}
.swiper-button-prev,
.swiper-button-next {
  opacity: 1 !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
  background: #f1f1f1;
  color: #fff;
  border-radius: 6px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 14px !important;

  font-weight: bold;
}
.swiper-button-prev,
.swiper-button-next {
  background: #e1e9ff;
  color: #0a1b46;
  border-radius: 6px;
}
.addUserPic .profileSize{
  width: 130px;
  height: 130px;
  border-radius: 50%;

}
.addUserPic {
  width: 130px;
  height: 130px;
  position: relative;
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  background-position: center;
  background-size: contain;

  /* background: url(../images/Icons/SVG/Ellipse\ 60.png) no-repeat center center; */
  background-size: cover;
  padding: 0px;
  border: 0px solid #208d8e;
}

.addUserPic .custom-file-label {
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 1;
  height: 145px;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 4;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 12px !important;
  color: #c3c3c3;
  cursor: pointer;
  border: 0px;
}

.addUserPic .custom-file-label::after {
  position: absolute;
  content: "" !important;
  top: 0px;
  right: 0px;
  line-height: 2;
  padding: 0;
  color: #a0a0a0;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 20px;
  width: 100%;
  text-align: center;
  height: 100px;
  cursor: pointer;
}

.addUserPic .custom-file-input {
  border: 0px;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 50px;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.addPicIcon {
  position: absolute;
  left: 56%;
  bottom: 3px;
  color: #383838;
  font-size: 15px;
  margin-left: 18px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  background: #ffffff;
  color: #5565b6;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  /* box-shadow: 0 0.5em 0.8rem rgba(0, 0, 0, 0.15) !important; */
}

.profileImg {
  position: relative;
  top: -73px;
}
.companylogo .addUserPic {
  background:none !important;
}

#profileCompanyLogo .addUserPic {
  width: 150px;
  height: 90px;
  border: 1px dashed #5565b6;
  border-radius: 10px;
}

.missionText {
  border: 1px solid #e1e9ff;
  border-radius: 20px;
  padding: 15px;
}

#organizationModal .modal-dialog {
  background: transparent;
  box-shadow: 0px 0px 00px #0000000d;
  border-radius: 20px;
}

/* OKR */

.cursor-pointer {
  cursor: pointer;
}

.row-main {
  min-height: 100vh;
}

.cols-main {
  padding-top: 40px;
}

.border-right-custom {
  border-right: 1px solid var(--custom-color-7);
}

.poppinsRegular {
  font-family: "Poppins";
}
.poppinsSemibold {
  font-family: "poppins";
  font-weight: 600;
}
.headingTitleColors {
  color: #1b222b;
}
.modalHeadingColor {
  color: #333333;
}
.viewAllIconColor {
  color: #7c7a7a;
}
.homepage-subtitle-color {
  color: #85878b;
}
h1 {
  font-size: 2rem;
  /* font-size: 32px; */
}

h2 {
  font-size: 1.5rem;
  /* font-size: 24px; */
}

h3 {
  font-size: 1.17rem;
  /* font-size: 18px; */
}

h4 {
  font-size: 1rem;
  /* font-size: 16px; */
}

h5 {
  font-size: 0.83rem;
  /* font-size: 14px; */
}

h6 {
  font-size: 0.67em;
  /* font-size: 12px; */
}

span,
p {
  font-size: 14px;
  /* font-size: 16px; */
}

.font-size-12 {
  font-size: 12px;
}
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.darkGrey {
  color: #5c5f65;
}

.lightGrey {
  color: #acacac;
}

.greyLight {
  color: #85878b;
}

.letter-spacing {
  letter-spacing: 1px;
}

.darkGrey {
  color: #5c5f65;
}
.lightGrey {
  color: #acacac;
}
.greyLight {
  color: #85878b;
}
.letter-spacing {
  letter-spacing: 1px;
}

.center-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.let-started-page {
  min-height: 100vh;
  background: url("../images/background/leaf-bg.webp");
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginotp-page {
  min-height: 100vh;
  background: url("../images/background/leaf-bg.webp");
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page {
  min-height: 100vh;
  background-color: var(--theme-color-primary);
}

/* .login-box {
  background: #ffffff;
  box-shadow: 0px 0px 99px #000000cc;
  border-radius: 20px;
  padding: 80px 40px;
} */

.verifyotp-box {
  border: 1px solid var(--custom-color-4);
  border-radius: 10px;
  padding: 40px 40px;
  max-width: 350px;
}

/* / common /  */
body[data-layout="detached"] .container-fluid {
  max-width: 95%;
}

#layout-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 125px;
  background-color: #fff;
  /* background: transparent linear-gradient(90deg, #252525 0%, #252525 100%) 0% 0% no-repeat padding-box; */
  background-size: cover;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
}

body[data-layout="detached"] #page-topbar {
  position: absolute;
  top: 0px;
  width: 100%;
}

/* body[data-layout="detached"] #layout-wrapper::after {
  height: 0px;
} */

.smallIcon {
  height: 60px;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;
}

body[data-layout="detached"] #sidebar-menu {
  padding-bottom: 90px;
}

#sidebar-menu {
  padding: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.metismenu li {
  display: block;
  width: 100%;
}

#sidebar-menu ul li a {
  display: block;
  padding: 0.8rem 1.5rem;
  color: #85878b;
  position: relative;
  font-size: 14.4px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

body[data-topbar="colored"] #page-topbar {
  background: #fff;
  z-index: 9999;
}

.let-started-page {
  min-height: 100vh;
  background: url("../images/background/leaf-bg.webp");
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verifyotp-box {
  border: 1px solid var(--custom-color-4);
  border-radius: 10px;
  padding: 40px 40px;
  max-width: 350px;
}

.let-started-box {
  display: inline-block;
  width: auto;
}

.loginBanner {
  height: 100%;
  /* background: url("../images/signUp_bg.jpg");
  background-position: center center;
  background-size: cover; */
  /* background-color: #040605; */
}
.dashboard-hanuman-img {
  height: 100%;
  /* background: url("../images/Home/hanuman.png"); */
  background-position: center center;
  background-size: cover;
}

/* Otp verification */
.center-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-box {
  background: #ffffff;
  /* box-shadow: 0px 0px 5px #000000cc; */
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 20px;
  padding: 80px 40px;
}

.verifyotp-box {
  border: 1px solid var(--custom-color-4);
  border-radius: 10px;
  padding: 40px 40px;
  max-width: 350px;
}
.Verifyotp-box {
  width: auto;
  justify-content: center;
  /* display: flex; */
  align-items: center;
}
/* Home page */
.get-started-page {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000001a;
  opacity: 1;
}

/* EASYOKR */

#pills-registration .circleNo {
  border: 1px solid blue;
  border-radius: 50%;
  padding: 10px 15px;
  background-color: #fff;
}

#pills-registration .nav-pills .nav-link.active,
#pills-registration .nav-pills .show > .nav-link {
  color: blue;
  background-color: transparent;
}

#pills-registration .nav-pills .nav-link.active .circleNo {
  border: 2px dashed blue;
  background-color: lightblue;
  color: #fff;
}

.library-nav-tab-container .nav-link,
.nav-link:hover,
.nav-link:focus,
.nav-link:focus-visible {
  color: blue;
  border: 0px solid #050908;
  background-color: transparent;
  box-shadow: none;
}

.tab-border {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 86%;
  margin: 0 auto;
  left: 15px;
  right: 0;
  top: 19px;
  z-index: 1;
}
li.nav-item {
  z-index: 2;
}
.nav-pills .nav-link {
  color: #95b3ff;
  padding: 0px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #6993ff;
  font-weight: 600;
  background-color: transparent;
}

.nav-pills .nav-link.active .circleNo {
  border: 2px dashed #6993ff;
  background-color: lightblue;
  color: #fff;
}
.nav-pills .nav-link .circleNo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #95b3ff;
  border-radius: 50%;
  background: #fff;
  width: 40px;
  height: 40px;
  margin: auto;
  color: #95b3ff;
}

.wizard-content-left {
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.45);
  background-image: url("https://i.ibb.co/X292hJF/form-wizard-bg-2.jpg");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  padding: 30px;
}
.wizard-content-left h1 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
}

.form-wizard {
  padding: 30px;
}
.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  /* webkit-animation: click-radio-wave 0.65s; */
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}
.form-wizard .wizard-form-radio input[type="radio"] ~ label {
  padding-left: 10px;
  cursor: pointer;
}
.form-wizard .form-wizard-header {
  text-align: center;
}
.form-wizard .form-wizard-next-btn,
.form-wizard .form-wizard-previous-btn,
.form-wizard .form-wizard-submit {
  background-color: #d65470;
  color: #ffffff;
  display: inline-block;
  min-width: 100px;
  min-width: 120px;
  padding: 10px;
  text-align: center;
}
.form-wizard .form-wizard-next-btn:hover,
.form-wizard .form-wizard-next-btn:focus,
.form-wizard .form-wizard-previous-btn:hover,
.form-wizard .form-wizard-previous-btn:focus,
.form-wizard .form-wizard-submit:hover,
.form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
}
.form-wizard .wizard-fieldset {
  display: none;
}
.form-wizard .wizard-fieldset.show {
  display: block;
}
.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.form-wizard .form-wizard-previous-btn {
  background-color: #fb1647;
}
/* .form-wizard .form-control {
  font-weight: 300;
  height: auto !important;
  padding: 15px;
  color: #888888;
  background-color: #f1f1f1;
  border: none;
}
.form-wizard .form-control:focus {
  box-shadow: none;
}
.form-wizard .form-group {
  position: relative;
  margin: 25px 0;
}
.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
} */
.form-wizard .focus-input .wizard-form-text-label {
  color: #d65470;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
}
.form-wizard .form-wizard-steps {
  margin: 30px 0;
}
.form-wizard .form-wizard-steps li {
  width: 20%;
  float: left;
  position: relative;
}
.form-wizard .form-wizard-steps li::after {
  background-color: #5565b6;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
  width: 100%;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
.form-wizard .form-wizard-steps li p {
  color: #95b3ff;
}
.form-wizard .form-wizard-steps li span {
  background-color: #fff;
  border: 2px solid #95b3ff;
  color: #95b3ff;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 37px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 1;
}
.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
}
.form-wizard .form-wizard-steps li.active span,
.form-wizard .form-wizard-steps li.activated span {
  background-color: #6993ff;
  color: #ffffff;
}

.form-wizard .form-wizard-steps li.active p,
.form-wizard .form-wizard-steps li.activated p {
  font-weight: 600;
  color: #6993ff;
}
.form-wizard .form-wizard-steps li.active::after,
.form-wizard .form-wizard-steps li.activated::after {
  background-color: #6993ff;
  left: 50%;
  width: 50%;
  border-color: #6993ff;
}
.form-wizard .form-wizard-steps li.activated::after {
  width: 100%;
  border-color: #d65470;
}
.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}
.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }
  #sidebar-menu ul li .hideText span {
    font-size: 8px;
  }
  .vertical-menu {
    min-width: 80px !important;
    max-width: 80px !important;
  }
  body[data-layout="detached"] .page-content {
    margin-left: 75px !important;
  }
  .btnOrganization {
    font-size: 14px !important;
  }
  #sidebar-menu ul li a {
    padding: 0.2rem 0rem !important;
  }
  .msm {
    font-size: 14px;
  }
  .hiddenBannerText {
    font-size: 4rem;
  }
}
.differentiator {
  background: #f7f9ff;
  border-radius: 24px;
  text-align: center;
}
.copyIcon {
  position: absolute;
  top: 50px;
  right: 17px;
}

#accordionFlushExample1 .accordion-button:not(.collapsed) {
  background: #ffffff;
  box-shadow: 0px 5px 20px #0000000d;
  border-radius: 12px;
}

#accordionFlushExample .accordion-button:not(.collapsed) {
  /* background: #f7f9ff !important; */
  border-radius: 12px !important;
}

.organizationNo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6d6fe;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #acacac;
  font-size: 12px;
  z-index: 99999;
}
.accordion-flush .accordion-collapse {
  background: #ffffff;
  box-shadow: 0px 5px 20px #0000000d;
  border-radius: 12px;
}
/* .vLine {
  border-left: 1px solid #EAF0FF;
  height: 100%;
  position: absolute;
  left: 56px;
  top: 0;
  z-index: 999;
} */
.vLine {
  border-left: 1px solid #eaf0ff;
  height: 100%;
  position: absolute;
  top: 0;
}
.ownerImg {
  margin-left: -20px;
}
.scoreLine {
  border-left: 3px solid #acacac;
  height: 30px;
}
.activeStatus {
  background: #e1ffe3;
  color: #39b742;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}
.inactiveStatus {
  background: #FFE4E4;
  color: #E22A2A;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}
.pendingStatus {
  background: #e1ffe3;
  color: #B79139;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}
.pendingStatus {
  background: #FFFFC2;
  color: #b79139;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}
.inactiveStatus {
  background: #ffe4e4;
  color: #e22a2a;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}

/* Setting Navtab start */

.nav-tabs-custom .nav-item .nav-link.active {
  color: #6993ff;
}

.nav-tabs-custom .nav-item .nav-link:hover {
  color: #6993ff;
}

.nav-tabs-custom .nav-item .nav-link:after {
  background: #6993ff;
  height: 3px;
  border-radius: 5px;
}
.nav-tabs-custom .nav-item .nav-link {
  padding: 0.5rem 2rem;
  font-size: 14px;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: #4c2a5f;
}

.nav-tabs-custom .nav-item .nav-link:after {
  background: #d6a617;
}

.nav-tabs-custom .nav-item .nav-link {
  padding: 0.5rem 2rem;
}

.nav-tabs-custom {
  border-bottom: 2px solid #f6f6f6;
}
.nav-tabs-custom .nav-item {
  position: relative;
  color: #343a40;
}
.nav-link {
  color: #252525;
  /* font-weight: 600; */
}
.nav-tabs-custom .nav-item .nav-link {
  border: none;
}
.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #6993ff;
  height: 2px;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -1px;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.nav-tabs-custom .nav-item .nav-link.active {
  color: #6993ff;
  /* font-weight: 600; */
}
.nav-tabs-custom .nav-item .nav-link.active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Setting Navtab end */

/* . custom-toggle-switch-with-text  */
.custom-toggle-switch-with-text.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 26px;
}

.custom-toggle-switch-with-text.switch input {
  display: none;
}

.custom-toggle-switch-with-text.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2ab934;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.custom-toggle-switch-with-text.switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-toggle-switch-with-text.switch input:checked + .slider {
  background-color: #941c26;
}

.custom-toggle-switch-with-text.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.custom-toggle-switch-with-text.switch input:checked + .slider:before {
  -webkit-transform: translateX(72px);
  -ms-transform: translateX(72px);
  transform: translateX(72px);
}

/*------ ADDED CSS ---------*/
.custom-toggle-switch-with-text.switch .slider:after {
  content: "Active";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.custom-toggle-switch-with-text.switch input:checked + .slider:after {
  content: "Inactive";
}

.css-ascpo7-MuiStepLabel-root {
  display: block !important;
  text-align: center;
}
.css-vnkopk-MuiStepLabel-iconContainer {
  justify-content: center;
  margin-bottom: 10px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  border: 2px dashed #6993ff;
  border-radius: 50%;
  fill: #c6d6ff !important;
  background-color: #c6d6ff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
  fill: #fff !important;
  border: 2px solid #95b3ff;
  border-radius: 50%;
}

.css-117w1su-MuiStepIcon-text {
  fill: #6993ff !important;
  font-size: 0.7rem;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #fff !important;
  border: 2px solid #6993ff !important;
  fill: #6993ff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed
  .css-z7uhs0-MuiStepConnector-line {
  border-color: #5565b6 !important;
}
.css-j5w0w9-MuiStepConnector-root {
  margin-top: -15px !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #6993ff !important;
  font-weight: 600 !important;
}
.css-1vyamtt-MuiStepLabel-labelContainer {
  color: #95b3ff !important;
}

.modalDialog {
  max-width: fit-content !important;
  max-height: fit-content;
  background-color: transparent;
  box-shadow: none;
}

.iconTop {
  top: -14px;
  position: relative;
}

.owl-prev {
  position: absolute;
  top: 103px;
  left: -44px;
}

.owl-next {
  position: absolute;
  top: 103px;
  right: -44px;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: #8aa0db !important;
  border-radius: 6px;
  width: 30px;
  height: 30px;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
  background: #dbd6d6 !important;
  color: #fff !important;
}
.owl-carousel .owl-item img {
  display: block;
  width: 42px !important;
  height: 42px !important;
  object-fit: cover !important;
  border-radius: 50%;
}
/* .owl-dots {
  display: none;
} */

.headerSelect .form-select {
  font-size: 14px;
  color: #212529;
  background-color: #EAF0FF;
  border: none;
}

.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(13,110,253,.25);
}
#shareInvite{
  display: none;
}

/* .CircularProgressbar .CircularProgressbar-path {
  stroke: #6993ff !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;

} */

.CircularProgressbar .CircularProgressbar-text {
  font-weight: bold;
}
.editable{
  background-color: #d6d6d6;
}
.deleteModalC{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
input[type="number"] {
  -webkit-appearance: none; /* Remove default styles for WebKit browsers */
  margin: 0; /* Reset margin */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
  max-height: none; /* Reset any max-height setting */
  overflow: hidden; /* Reset any overflow setting */
}

input[type="number"]::placeholder {
text-align: center !important;
}
.Organization-img{
  height: 44px;
    width: 44px;
    object-fit: cover;
    border-radius: 50%;
}

.Organization-img-square{
  height: 52px;
    width: 52px;
    object-fit: cover;
}
.setting-Profile-img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: fill;
}
.dashboardSlider .owl-stage-outer {
  padding-left: 48px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  opacity: 9;
  color:  #6993ff!important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 171px;
  background-color: #f7f9ff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  left: -14%;
  top: -16px;
  position: absolute;
  z-index: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: rgb(247, 249, 255);
  border-radius: .25rem;
}
